import React from 'react';
import { Create, useRedirect } from 'react-admin';

import { OwnerCompanyDataForm } from '../forms/OwnerCompanyDataForm';
import { SimpleFormAdv } from '../../../components/forms/SimpleFormAdv';


export const OwnerCompanyDataCreate = (props) => {
    const redirect = useRedirect();

    const onSuccess = () => {
        redirect(props.basePath);
        window.location.reload();
    };

    const toolbarCompProps = {
		useSaveButtonEx: true,
		saveButtonExProps: { onSuccess },
	};

	return (
		<Create {...props}>
			<SimpleFormAdv toolbarCompProps={toolbarCompProps}>
				<OwnerCompanyDataForm />
			</SimpleFormAdv>
		</Create>
	);
};
