import React from 'react';
import { TextInput, required } from 'react-admin';

import { GridForm, GridInput, FormGroupGrid } from '../../../components/GridForm';
import { TimeZoneSelect } from '../../../components/inputs/TimeZoneSelect';


export const TerminalsEditForm = (props) => (
    <GridForm {...props}>
        <FormGroupGrid>
            <GridInput sm={2} component={TextInput} source='id' disabled />
            <GridInput sm={10} component={TextInput} source='name' validate={[required()]} autoFocus />
            <GridInput sm={7} component={TextInput} source='short_name' />
            <GridInput sm={5} component={TextInput} source='account_code' />
        </FormGroupGrid>

        <FormGroupGrid>
            <GridInput sm={8} component={TextInput} source='address' />
            <GridInput sm={4} component={TimeZoneSelect} source='time_zone' validate={[required()]} />
        </FormGroupGrid>
    </GridForm>
);
