import OwnerCompanyDataIcon from '@material-ui/icons/AccountBoxOutlined';

import { OwnerCompanyDataCreate } from './Create/Create';
import { OwnerCompanyDataEdit } from './Edit/Edit';
import { OwnerCompanyDataList } from './List/List';


export {
    OwnerCompanyDataList,
    OwnerCompanyDataEdit,
    OwnerCompanyDataCreate,
};

export const ownerCompanyDataResource = {
    list: OwnerCompanyDataList,
    edit: OwnerCompanyDataEdit,
    create: OwnerCompanyDataCreate,
    icon: OwnerCompanyDataIcon,
};
