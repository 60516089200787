import React from 'react';

import { List, Datagrid, TextField, EmailField } from 'react-admin';

import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { PhoneField } from '../../../components/fields/PhoneField';


export const OwnerCompanyDataList = (props) => (
    <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
    >
        <ScrollingWrapper>
            <Datagrid rowClick='edit'>
                <TextField source='full_name' />
                <TextField source='short_name' />
                <EmailField source='email' />
                <EmailField source='support_email' />
                <PhoneField source='phone' />
                <TextField source='web_site' />
                <TextField source='logo_link' />
                <TextField source='switchboard' />
            </Datagrid>
        </ScrollingWrapper>
    </List>
);
