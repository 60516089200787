import React from 'react';

import { List, Datagrid, TextField, EditButton } from 'react-admin';

import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { DatagridWithBlockDeleteProps } from '../../../components/_helpers/props/DatagridProps';
import { stylesForColorInheritInList } from '../../../components/_helpers/useStyles';

import { TerminalsFilter } from './ListFilters';


const defaultSort = { field: 'id', order: 'DESC' };


export const TerminalsList = (props) => {
    const classes = stylesForColorInheritInList();

    return (
        <List
            {...props}
            sort={defaultSort}
            exporter={false}
            bulkActionButtons={false}
            filters={<TerminalsFilter />}
            classes={classes}
        >
            <ScrollingWrapper>
                <Datagrid
                    {...DatagridWithBlockDeleteProps}
                    rowClick='show'
                >
                    <TextField source='id' />
                    <TextField source='name' />
                    <TextField source='short_name' />
                    <TextField source='account_code' />
                    <TextField source='time_zone' />
                    <TextField source='address' />

                    <EditButton />
                </Datagrid>
            </ScrollingWrapper>
        </List>
    );
};
