import React from "react";
import { Resource } from "react-admin";
import { Route } from "react-router-dom";

import { ordersResource } from "../../resources/orders/";
import { tasksResource } from '../../resources/tasks/';
import { entitiesResource } from '../../resources/entities/';

import { pricelistArticlesResource } from "../../resources/pricelist-articles/";
import { pricelistHolidaysResource } from "../../resources/pricelist-holidays/";

import { apiKeysRouts } from "../../pages/settings/APIKeys";
import Messages from "../../resources/messages/List/List";
import { OrdersMultipleChangesPage } from "../../resources/orders/MultipleChanges2";

export const osfcEmployeeResources = [
	<Resource name="orders" {...ordersResource} />,
	<Resource name="tasks" {...tasksResource} create={null} />,
	<Resource name="entities" {...entitiesResource} />,
	// TODO: try to develop some StaticResource that fetch data locally or if data data doesn't change often
	<Resource name="ports" intent="registration" />,
	<Resource name="areas" intent="registration" />,
	<Resource name="users" intent="registration" />,
	<Resource name="customer-companies" intent="registration" />,
	<Resource name="order-statuses" intent="registration" />,
	<Resource name="task-statuses" intent="registration" />,
	<Resource name="task-history" intent="registration" />,
	<Resource name="entity-history" intent="registration" />,
	<Resource name="cooler-types" intent="registration" />,
	<Resource name="order-status" intent="registration" />,
	<Resource name="outgoing-doc-statuses" intent="registration" />,
	<Resource
		name="order-pricelist"
		intent="registration"
		options={{ hasAdd: true, hasEdit: true }}
	/>,
	<Resource name="drivers" intent="registration" />,
	<Resource name="transporters" intent="registration" />,
	<Resource name="trucks" intent="registration" />,
	<Resource name="trailers" intent="registration" />,
	<Resource name="terminal" intent="registration" />,
	<Resource name="api-keys" intent="registration" />,

	<Resource {...pricelistArticlesResource} name="pricelist-articles" />,
	<Resource {...pricelistHolidaysResource} name="pricelist-holidays" />,
	<Resource name="pricelist-special-price" intent="registration" />,
	<Resource name="pricelist-articles-autocomplete" intent="registration" />,
	<Resource name="company-users" intent="registration" />,
];

export const osfcEmployeeRoutes = [
	...apiKeysRouts,
	<Route exact path="/messages" component={Messages} />,
	<Route
		exact
		path="/orders/multiple-changes"
		component={OrdersMultipleChangesPage}
	/>,
];
