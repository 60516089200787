import React from 'react';

import { TextInput, required } from 'react-admin';

import { GridForm, GridInput, FormGroupGrid } from '../../../components/GridForm';
import { EmailInput, PhoneInput } from '../../../components/inputs';


const requiredValidator = [required()];

export const OwnerCompanyDataForm = (props) => (
    <GridForm {...props}>
        <FormGroupGrid>
            <GridInput 
                xs={12} 
                sm={4} 
                component={TextInput} 
                source="full_name" 
                validate={requiredValidator} 
            />

            <GridInput 
                xs={12} 
                sm={4} 
                component={TextInput} 
                source="short_name" 
                validate={requiredValidator} 
            />

            <GridInput 
                xs={12} 
                sm={4} 
                component={EmailInput} 
                source="email" 
                validate={requiredValidator} 
            />

            <GridInput 
                xs={12} 
                sm={4} 
                component={EmailInput} 
                source="support_email" 
                validate={requiredValidator} 
            />

            <GridInput 
                xs={12} 
                sm={4} 
                component={PhoneInput} 
                source="phone" 
                validate={requiredValidator} 
            />

            <GridInput 
                xs={12} 
                sm={4} 
                component={TextInput} 
                source="switchboard" 
                validate={requiredValidator} 
            />

            <GridInput 
                xs={12} 
                sm={4} 
                component={TextInput} 
                source="web_site" 
                validate={requiredValidator} 
            />

            <GridInput 
                xs={12} 
                sm={4} 
                component={TextInput} 
                source="logo_link" 
                validate={requiredValidator} 
            />
        </FormGroupGrid>
    </GridForm>
);
