import React from 'react';

import { List, useDataProvider } from 'react-admin';

import { stylesForColorInheritInList } from '../../../components/_helpers/useStyles';
import { NeedOfWorkforceFilter } from './ListFilters';
import { WorkforceGrid } from './WorkforceGrid';
import { ToggleDatesLaborForecastEnum } from './ToggleDatesFilter';


const defaultFilters = (terminal_id) =>{
	return { 
		"eta_date|etd_date":[ToggleDatesLaborForecastEnum.TODAY],
		"terminal_id": [terminal_id]
	}
} 

export const NeedOfWorkforceList = ({permissions, ...props}) => {
	const classes = stylesForColorInheritInList(); 
	const dataProvider = useDataProvider();
	const [isLoading, setIsLoading] = React.useState(true);
	const [ terminalData, setTerminalData ] = React.useState([]);

	React.useEffect(() => {
		dataProvider.getList("terminal", {
			pagination: {page: 0, perPage: -1},
			sort: {field: 'id', order: 'ASC'},
			filter: {},
		}).then((data) => {
			setTerminalData(data.data);
			setIsLoading(false);
		});
	}, [ dataProvider ]);

	if (isLoading) {
        return null; 
    }

	return (
		<List
			{...props}
			exporter={false}
			bulkActionButtons={false}
			filters={<NeedOfWorkforceFilter {...props}/>}
			actions={null}
			classes={classes}
			pagination={false}
			filterDefaultValues={defaultFilters(terminalData[0]["id"])}
		>
			 <WorkforceGrid {...props}/>	
		</List>
	);
};
